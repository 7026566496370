(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice.line')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.line', {
      url: '/referee-new-invoice/line/{licenceId}/inv/{lineId}',
     
          templateUrl: 'home/referee-new-invoice/line/line.tpl.html',
          controller: 'RefereeLine',
          controllerAs: 'vm'
        ,
      resolve:{
       
        refereeLicense: function (RefereeLicence,  $stateParams){
          return RefereeLicence.get({licenceId :  $stateParams.licenceId}).$promise;
    },
    refId:function ($stateParams){
        return $stateParams.licenceId;
    },
    lineId:function ($stateParams){
        return $stateParams.lineId;
    },
    lineread:function (InvoiceRefLine,  $stateParams){
          return InvoiceRefLine.get({lineId :  $stateParams.lineId}).$promise;
      },
    Receptores: function(receptores,$stateParams){
        return receptores.query();
    }
      }});
  }
}());


